































































































import { Vue, Component } from 'vue-property-decorator';
import ffButton from '@/components/public/Button.vue';
import { namespace } from 'vuex-class';
const appStore = namespace('app');
@Component({
  components: {
    ffButton
  }
})
export default class Detail extends Vue {
  @appStore.State((state) => state.transify) private transify: object;
  @appStore.State((state) => state.feedbackHistory)
  private feedbackHistory: object[];
  @appStore.State((state) => state.questions) private questions: any;
  get Detail(): object {
    return this.feedbackHistory[this['$route'].params.id - 1];
  }
  get qaList(): any {
    return this.Detail['feedback_answers'];
  }
  setDate(time: number): string {
    const date = new Date(time * 1000);
    const [day, month, year] = [
      date.getDate() < 10 ? '0' + date.getDate() : date.getDate(),
      date.getMonth() + 1 < 10
        ? '0' + (date.getMonth() + 1)
        : date.getMonth() + 1,
      date.getFullYear()
    ];
    return `${day}.${month}.${year}`;
  }
  getAnswers(answers: Array<Array<number> | number | null>) {
    let answersMap = '';
    answers.forEach((item, index) => {
      let answerVal = '';
      if (item instanceof Array) {
        item.forEach((i, idx) => {
          if (idx + 1 >= item.length) {
            answerVal += `${this.questions[index].options[i].option}`;
          } else {
            answerVal += `${this.questions[index].options[i].option} , `;
          }
        });
      } else if (typeof item === 'number') {
        answerVal = this.questions[index].options[item].option;
      } else {
        return true;
      }
      if (index + 1 >= answers.length) {
        answersMap += `${answerVal}`;
      } else {
        answersMap += `${answerVal} > `;
      }
    });
    return answersMap;
  }
}
